import { Table, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import Footer from "../Footer/Footer";
import NewNavbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import { Input } from "../NewRegistration/Input";
import { FORM } from "./constants";
import { Button } from "react-bootstrap";
import { Avatar, Card } from "antd";
import pic from "../../image/arts_club2.jpg";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
// import NewNavbar from "../NavBar/NewNavbar";
let bro = false;
const keys = [
  "FB_MONTH",
  "FB_TOTAL",
  "FB_YEAR",
  "HGSF_MONTHLY_FEE_BILL_ID",
  "HGSF_STD_MFBILL_ID",
  "breakup",
];

export const ExistingFees = () => {
  let minOffset = 0,
    maxOffset = 30;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((x) => {
    return <option key={x}>{x}</option>;
  });
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [feesData, setFeesData] = useState([]);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };
  const [form, setForm] = useState({});
  const onChange = (e) => {
    const { value, name } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const onItemClick = ({ fieldName, value }) => {
    setForm((prev) => ({ ...prev, [fieldName]: value }));
  };
  // val bro=false;
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      //https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=HGSJ/1112/193
      const result = await axios.get(
        // `https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=HGSJ/1112/193`
        `https://hgs.onrender.com/paymentfees?reg_code=${form.reg_no}`
        //  `https://eschool.appsosindia.org/views/api/payment_details_new.php?reg_code=${form.reg_no}`
        //  `https://eschool.appsosindia.org/views/accounts/php/fee_enquiry.php?reg_no=${form.reg_no}&mobile=${form.mobile}&academic_year=${form.academic_year}&school_code=HGSF`
      );
      // console.log(result.data.payment_info);
      setLoading(false);
      setResult(result.data.payment_info?.length > 0 ? false : true);
      // console.log(result);
      setFeesData(result.data.payment_info);
      console.log(feesData, "feesdata");
      console.log(feesData.TOTAL_BALANCE, "feesData.TOTAL_BALANCE");
      if (feesData.TOTAL_BILL === 0) {
        bro = true;
        console.log(bro);
      } else {
        bro = false;
      }
      console.log(feesData.length);
      // if(!feesData.TOTAL_BILL){
      //   bro=true;

      // }
      // const datatbe=Array.from(feesData);
      // console(datatbe,"datate");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }
  function isObj(val) {
    return typeof val === "object";
  }
  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  const buildFormForCC = (data) => {
    const form = document.createElement("form");
    form.setAttribute("id", "nonseamless");
    form.setAttribute("name", "redirect");
    form.setAttribute("method", "post");
    form.setAttribute("action", data.url);

    const input = document.createElement("input");
    input.setAttribute("id", "encRequest");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "encRequest");
    input.setAttribute("value", stringifyValue(data["encRequest"]));
    form.appendChild(input);

    const input2 = document.createElement("input");
    input2.setAttribute("id", "access_code");
    input2.setAttribute("type", "hidden");
    input2.setAttribute("name", "access_code");
    input2.setAttribute("value", stringifyValue(data["accessCode"]));
    form.appendChild(input2);
    return form;
  };

  function postCC(details) {
    const form = buildFormForCC(details);
    // console.log(form);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const payment = async (amount, fees_id) => {
    const response = await axios.post(
      "https://kylo-ccavenue.onrender.com/api/ccavenue/create/order",
      {
        studentPhone: form.mobile,
        totalAmount: amount,
        schoolCode: "HGSV",
        reg_id: form.reg_no,
        fees_id: 1234,
        academic_year: form.academic_year,
      }
    );
    postCC(response.data);
  };

  const columns = [
    {
      title: "Month",
      dataIndex: "FB_MONTH",
      key: "FB_MONTH",
    },
    {
      title: "Breakup",
      dataIndex: "breakup",
      key: "breakup",
      render: (text, record) =>
        text.map((item) => (
          <div>
            <Typography.Link>
              {item.FEE_NAME} : {item.FB_AMOUNT}
            </Typography.Link>
          </div>
        )),
    },
    {
      title: "Total",
      dataIndex: "FB_TOTAL",
      key: "FB_TOTAL",
    },
    {
      title: "Year",
      dataIndex: "FB_YEAR",
      key: "FB_YEAR",
    },
    {
      title: "Monthly Id",
      dataIndex: "HGSF_MONTHLY_FEE_BILL_ID",
      key: "HGSF_MONTHLY_FEE_BILL_ID",
    },

    {
      title: "Student Bill Id",
      dataIndex: "HGSF_STD_MFBILL_ID",
      key: "HGSF_STD_MFBILL_ID",
    },
    {
      title: "Payment ",
      key: "Payment",
      dataIndex: "",
      render: (_, record) => (
        <button
          className="apply-btn cursor"
          onClick={() => payment(record.FB_TOTAL, record.HGSF_STD_MFBILL_ID)}
        >
          PAY NOW
        </button>
      ),
    },
  ];

  return (
    <div>
      <div className="home_main" onScroll={handleScroll}>
        <NewNavbar handleClick={handleClick} />
        {/* <Navbar isScrolled={isScrolled} handleClick={handleClick} /> */}
        <NavbarMenu isShow={isShow} handleClick={handleClick} />
        <div className="pt-5 mt-5 flex-center d-flex justify-content-center align-items-center">
          <div>
            <form
              onSubmit={submitForm}
              className="d-flex my-5 justify-content-center align-items-center flex-column"
            >
              <div className="row d-flex justify-content-center">
                {FORM["STEP_1"].map((item) =>
                  item.name !== "academic_year" ? (
                    <Input
                      value={item.value}
                      id={item.name}
                      readOnly={item.readOnly}
                      key={item.name}
                      {...item}
                      form={form}
                      onChange={onChange}
                      onClick={onItemClick}
                    />
                  ) : (
                    <>
                      <select
                        className="input-container mt-3 mx-1"
                        value={item.value}
                        id={item.name}
                        name={item.name}
                        key={item.name}
                        {...item}
                        onChange={onChange}
                        onClick={onItemClick}
                      >
                        {/* Placeholder text */}
                        <option value="">Select Academic Year</option>

                        {/* Render the yearList options */}
                        {yearList}
                      </select>
                    </>
                  )
                )}
              </div>

              <div className="flex-center">
                <Button
                  type="submit"
                  disabled={loading}
                  className="apply-btn cursor my-3 "
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </div>
        {result && feesData.TOTAL_BILL && (
          <div
            className="pt-5 mx-5"
            style={{
              // color: "#0076bd",
              textAlign: "center",
              marginBottom: "3em",
            }}
          >
            <center>
              <Card
                style={{ width: 300 }}
                cover={<img alt="example" src={pic} />}
                // actions={[
                //   <SettingOutlined key="setting" />,
                //   <EditOutlined key="edit" />,
                //   <EllipsisOutlined key="ellipsis" />,
                // ]}
              >
                <div
                  style={{
                    // color: "#0076bd",
                    textAlign: "center",
                    marginBottom: "20px",
                  }}
                >
                  <p>Total Bill={feesData.TOTAL_BILL}</p>
                  <p>Total Receipt={feesData.TOTAL_RECEIPT}</p>
                  <p>Total Balance={feesData.TOTAL_BALANCE}</p>
                </div>
                {feesData.TOTAL_BALANCE ? (
                  <Button
                    className="apply-btn cursor"
                    onClick={() => payment(feesData.TOTAL_BALANCE, "")}
                  >
                    PAY NOW
                  </Button>
                ) : (
                  <div>
                    <h4>No Payment Needed as of now.</h4>
                  </div>
                )}
                {/* <Meta
                avatar={
                  <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />
                }
                title="Card title"
                description="This is the description"
              /> */}
              </Card>
            </center>

            {/* <div>
              <p>{feesData.TOTAL_BILL}</p>
              <p>{feesData.TOTAL_RECEIPT}</p>
              <p>{feesData.TOTAL_BALANCE}</p>
            </div> */}
            {/* <center>
              <Button
                className="apply-btn cursor"
                onClick={() => payment(feesData.TOTAL_BALANCE, "")}
              >
                Payment
              </Button>
            </center> */}
            {/* <Table
              columns={columns}
              // dataSource={feesData}
              loading={loading}
              rowKey="HGSF_MONTHLY_FEE_BILL_ID"
            /> */}
          </div>
        )}
        {bro && (
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h5>No Result Found, Try another</h5>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};
